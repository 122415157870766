<template>
    <div id="app" class="dictionary-list">
        <div class="" v-if="!loaded && !error">
            <div class="app-loading">
                <div id="loading-bar-spinner" class="spinner">
                    <div class="spinner-icon"></div>
                </div>
            </div>
        </div>
        <div v-if="error">
            <p>Der skete en fejl. Prøv igen.</p>
        </div>
        <div v-if="loaded && !error">
            <div class="filter-options">

                <div class="grid-x grid-margin-x">
                    <div class="cell auto">
                        <strong>Vælg et forbogstav</strong>
                        <div class="alphabet">
                            <a v-for="letter in letters" @keyup.enter="goToLetter(letter, alphaItems(letter).length == 0)" @click="goToLetter(letter, alphaItems(letter).length == 0)" v-bind:tabindex="alphaItems(letter).length == 0 ? -1 : 0" v-bind:aria-label="alphaItems(letter).length == 0 ? '' : 'Gå til ord der starter med ' + letter" v-bind:class="{disabled: alphaItems(letter).length == 0}">{{letter}}</a>
                        </div>
                    </div>
                </div>

                <div class="filter-search-bar">
                    <div class="grid-x grid-margin-x">
                        <div class="cell large-3">
                            <label for="search">Indtast søgeord</label>
                        </div>
                        <div class="cell large-9">
                            <div class="filter-search-bar__input">
                                <div class="input-container">
                                    <input type="text" id="search" class="has-icon" v-model="search" placeholder="Søg" />
                                    <span class="input-icon">
                                        <svg aria-label="Søg i ordbogen" class="icon search-icon" v-bind:class="{ hidden: hideSearchIcon }">
                                            <use href="#icon-search"></use>
                                        </svg>
                                    </span>
                                    <span class="input-icon">
                                        <svg aria-label="Annuller søgning" class="icon clear-icon" v-bind:class="{ hidden: hideClearIcon }" tabindex="0" @keyup.enter="clearSearch()" @click="clearSearch()">
                                            <use href="#icon-close"></use>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="grid-x grid-margin-x">
                <div class="cell large-12">
                    <template v-for="letter in letters">
                        <template v-if="alphaItems(letter).length > 0 || search==''">
                            <a class="letter-link" :name="letter"></a>
                            <div class="header">
                                <h2>{{letter}}</h2>
                            </div>

                            <ul class="accordion" v-bind:data-letter="letter" data-accordion data-allow-all-closed="true">
                                <template v-for="item in alphaItems(letter)">
                                    <li v-bind:data-id="item.Id" class="accordion-item" data-accordion-item>
                                        <a @keyup.enter="toggleAccordion(item.Id)" @click="toggleAccordion(item.Id)" tabindex="0" class="accordion-title" aria-label="Åbn indhold">{{item.Header}}</a>
                                        <div class="accordion-content" data-tab-content v-html="item.Text">
                                        </div>
                                    </li>
                                </template>
                            </ul>
                        </template>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        name: "app",
        props: ["umbracoId"],
        data() {
            return {
                loaded: false,
                error: false,
                hideSearchIcon: false,
                hideClearIcon: true,
                items: [],
                search: "",
                letters: 'ABCDEFGHIJKLMNOPQRSTUVWXYZÆØÅ'.split('')
            }
        },
        mounted: function () {
            this.getItems();
        },
        computed: {
            filteredList() {
                this.addAlert(this.search);
                return this.items.filter(item => {
                    return item.Header.toLowerCase().includes(this.search.toLowerCase())
                })
            }
        },
        watch: {
            "search": function () {
                this.hideSearchIcon = this.search != "";
                this.hideClearIcon = !this.hideSearchIcon;
            }
        },
        methods: {
            addAlert: function (searchValue) {
                setTimeout(() => {
                    if (this.search == searchValue) {
                        $('.search-alert').remove();
                        if (searchValue == "") {
                            $('.filter-options').after(`<div class="search-alert" role="alert">Ordbogslisten indeholder ${this.filteredList.length} ord</div>`);
                        } else {
                            $('.filter-options').after(`<div class="search-alert" role="alert">Din søgning på ${searchValue} gav ${this.filteredList.length} resultater</div>`);
                        }
                    }
                }, 500);
            },
            gotoLetter: function () {
                return "";
            },
            alphaItems: function (l) {
                return this.filteredList.filter(i => { return i.Header.toLowerCase().indexOf(l.toLowerCase()) === 0; }).sort(this.compareLetters);;
            },

            getItems() {
                var self = this;

                axios
                    .get("/umbraco/surface/Dictionary/GetItems")
                    .then(function (response) {
                        var items = response.data;
                        self.items = items;
                        self.loaded = true;
                    })
                    .catch(function (error) {
                        console.log(error);
                        self.loaded = false;
                        self.error = true;
                    });
            },
            toggleAccordion(id) {
                var accordionItem = $('.dictionary-list .accordion-item[data-id=' + id + ']');
                accordionItem.toggleClass('is-active');
                accordionItem.find('.accordion-content').slideToggle(240);

                var title = accordionItem.find('.accordion-title');
                var label = title.attr('aria-label');
                if (label.indexOf('Åbn') > -1) {
                    title.attr('aria-label', 'Luk indhold');
                } else if (label.indexOf('Luk') > -1) {
                    title.attr('aria-label', 'Åbn indhold');
                }
            },
            goToLetter(letter, disabled) {
                if (!disabled) {
                    var selection = $(".letter-link[name=" + letter + "]");
                    $('.accordion[data-letter=' + letter + '] .accordion-title').eq(0).focus();
                    $('html, body').animate({
                        scrollTop: selection.offset().top
                    }, 240);
                }
            },
            compareLetters(a, b) {
              const letterA = a.Header.toUpperCase();
              const letterB = b.Header.toUpperCase();

              let comparison = 0;
              if (letterA > letterB) {
                comparison = 1;
              } else if (letterA < letterB) {
                comparison = -1;
              }
              return comparison;
            },
            clearSearch() {
                this.search = "";
            }
        }
    };
</script> 